import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Navigation, Footer, CopyrightLabel, SiteMap, SocialMedia, MessageDisplay } from '@fishrmn/fishrmn-components';
import { Link } from 'gatsby';
import 'semantic-ui-less/semantic.less';
import './layout.css';

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        fishermanWebsiteLayout(name: {eq: "@"}, components: {elemMatch: {fastId: {eq: "MessageDisplay"}}}) {
          components {
            data
          }
        }
        allFisherman {
          edges {
            node {
                       businessName 
          socialMedia {
            link 
            type 
          }
          logo 
            }
          }
        }
        allFishermanImages {
          edges {
            node {
              heroImages {
                url
              }
              galleryImages {
                url
              }
            }
          }
        }
      }
    `}
    render={queryData => {
      const businessData = queryData.allFisherman.edges[0].node;
      const imageData = queryData.allFishermanImages.edges[0].node;
      const data = { ...businessData, ...imageData };

      return (
        <div className="site-container">
          <div>
            {/*<MessageDisplay businessName={data.businessName} {...JSON.parse(queryData.fishermanWebsiteLayout.components[0].data)} />*/}
						<Navigation
               links={
                 [
                   {
                     "pageTitle": "Home",
                     "slug": "/",
                     "internal": true
                   },
                   {
                     "pageTitle": "Menu",
                     "slug": "/menu/",
                     "internal": true
                   },
                   {
                     "pageTitle": "Order Now",
                     "slug": "/order-now/",
                     "internal": true
                   }
                 ]
               }
               style={{border:"6px solid orange !important"}}
               backgroundColor={ "white" }
               bordered={ false }
               centerMobileNavItems={ true }
               evenSpacing={ false }
               fixed={ false }
               header={ data.businessName }
               linksAs={ "text" }
               logo={ data.logo }
               primaryContentPosition={ "left" }
               primaryContentCentered={ false }
               internalLinkComponent={Link}
            />
          </div>
          <div className="page-container">{children}</div>
          <Footer
             backgroundColor={ "primary" }
             horizontalAlign={ "center" }
             verticalAlign={ "middle" }
             columns={[
               <CopyrightLabel
                  company={ data.businessName }
                  phrase={ "All Rights Reserved" }
               />,
               <SiteMap
                  links={[
                    {
                      "pageTitle": "Home",
                      "slug": "/",
                      "internal": true
                    },
                    {
                      "pageTitle": "Menu",
                      "slug": "/menu/",
                      "internal": true
                    },
                    {
                      "pageTitle": "Order Now",
                      "slug": "/order-now/",
                      "internal": true
                    }
                  ]}
                  horizontal={ true }
                  bulleted={ false }
                  internalLinkComponent={ Link }
               />,
               <SocialMedia
                  socialMediaValues={ data.socialMedia }
                  buttonType={ "circle" }
                  centerHeader={ true }
                  colors={ "secondary" }
                  groupButtons={ false }
                  groupVertically={ false }
                  header={ "" }
                  inverted={ false }
                  labelPosition={ "left" }
                  showLabels={ false }
               />,
             ]}
          />
        </div>
      )
    }}
  />
);
